import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "ProtectedRoute";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import Approve from "views/FormApprove";
import ErrorPage from "views/ErrorPage";

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Routes>
          <Route
            path="auth/*"
            element={<ProtectedRoute Component={AuthLayout} />}
          />
          <Route
            path="admin/*"
            element={<ProtectedRoute Component={AdminLayout} />}
          />
          <Route path="/" element={<Navigate to="/auth" replace />} />
          <Route path="/form/approve/:id" element={<Approve />} />
          <Route path="/*" element={<ErrorPage />} />
        </Routes>
      </PersistGate>
    </Provider>
  );
};
export default App;
