import React from 'react';
import { useParams } from 'react-router-dom';
import { formApprove } from 'proxyUrl';
const Approve = () => {
    const params = useParams();

    React.useEffect(() => {
        // declare the data fetching function
        const approveData = async () => {
             fetch(formApprove, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id:params.id,
                }),
            });
            // const data = await res.json();
        }

        approveData()
        // make sure to catch any error
        .catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div>
            <>
  <meta charSet="utf-8" />
  <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <title />
  <link
    href="https://fonts.googleapis.com/css?family=Lato:300,400|Montserrat:700"
    rel="stylesheet"
    type="text/css"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n\t\t@import url(//cdnjs.cloudflare.com/ajax/libs/normalize/3.0.1/normalize.min.css);\n\t\t@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css);\n\t"
    }}
  />
  <link
    rel="stylesheet"
    href="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/default_thank_you.css"
  />
  <header className="site-header" id="header">
    <h1 className="site-header__title" data-lead-id="site-header-title">
      THANK YOU!
    </h1>
  </header>
  <div className="main-content">
    <i className="fa fa-check main-content__checkmark" id="checkmark" />
    <p className="main-content__body" data-lead-id="main-content-body">
      Thanks a bunch for filling that out. It means a lot to us, just like you
      do! We really appreciate you giving us a moment of your time today. Thanks
      for being you.
    </p>
  </div>
  <footer className="site-footer" id="footer">
    <p className="site-footer__fineprint" id="fineprint">
      Copyright ©2023 | Flio Travels
    </p>
  </footer>
</>

        </div>
    );
}

export default Approve;
