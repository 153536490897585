import React, { useEffect, useState } from "react";
import axios from "axios";
import { DeleteJobData, host, GetJobDataById } from "proxyUrl";
import { useParams, useNavigate } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function DeleteJob() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blogContentFromApi, setBlogContentFromApi] = useState();
  const [loading, setLoading] = useState(false);
  const [oldImage, setOldImage] = useState();
  const [user, setUser] = useState({
    title: "",
    metadescription: "",
    slug: "",
    metakeywords: "",
    metatitle: "",
    alttext: "",
    canonical: "",
    shortDescription: "",
  });
  //   console.log(blogContentFromApi);
  const GettingBlogblogContentFromApi = () => {
    axios
      .get(`${GetJobDataById}${id}`, {
        withCredentials: true,
      })
      .then((data) => {
        const jobs = data.data;
        setBlogContentFromApi(jobs);
      });
  };

  const handleDelete = async () => {
    setLoading(true);
    console.log(DeleteJobData,id,'dddddddddddddddddddddddddddddddddd');

    try {
      await axios
        .delete(`${DeleteJobData}/${id}`)
        .then((res) => {
          if (res.status === 204) {
            toast.success("Job Deleted succsesfully!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("something happened wrong!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          const delayfrfresh = () => {
            navigate("/admin/jobs");
          };
          // eslint-disable-next-line
          const delayRef = setTimeout(delayfrfresh, 3100);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            toast.error("Job Not Found in Record!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    GettingBlogblogContentFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUser({
      ...user,
      title: blogContentFromApi ? blogContentFromApi.title : "",

      shortDescription: blogContentFromApi
        ? blogContentFromApi.shortDescription
        : "",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogContentFromApi]);

  useEffect(() => {
    setOldImage(blogContentFromApi ? blogContentFromApi.image : "");
  }, [blogContentFromApi]);
  return (
    <div className="  my-10 flex h-[78vh] w-full flex-col items-center justify-center     gap-5 ">
      {/* <div className="input relative mx-2 mr-7 flex items-center justify-center rounded-lg border-2 border-blueSecondary   "> */}
      {/* <div className=" h-[300px] w-[500px] rounded-lg   ">
          <img
            className="h-full w-full rounded-md object-cover "
            src={`${host}/public/blog/${oldImage}`}
            alt=""
          />
        </div> */}
      {/* </div> */}
    <div className="  w-[50%] ">
    <h2 className="  mb-0  text-2xl font-semibold text-primaryBlue ">
      <span>Title:</span> iuyftdfyuiiuy
      </h2>
      <p>
        <span>Short Description:</span>rterterterte
      </p>
      <p>
        <span>Shor Description:</span>rterterterte
      </p>
    </div>
      <button
        className=" rounded-full  bg-red-400 px-10 py-5 text-xl text-white shadow-md duration-300 hover:bg-red-500 "
        onClick={handleDelete}
        disabled={loading}
      >
        Delete
      </button>
      <ToastContainer />
    </div>
  );
}

export default DeleteJob;
