 //export const host = "http://localhost:4001";
export const host = "https://api.creedinfotech.com";

export const loginRoute = host+'/signin';
export const logOut = host+'/admin/sign-out';
export const userAuthentication = host+'/user/authentication';

// export const getUserList = host+'/admin/user/list';
export const addResister = host+'/register/add';
export const getAllUserList = host+'/user/list';
export const getOneUserById = host+'/getoneuserbyid';
export const UploadUserImage = host+'/upload_user_image';
export const userLogout = host+'/admin/sign-out';
export const formApprove = host+'/approve';
export const GetBookingDataByUserId = host+'/get/registerdata';
export const GetOneBookingDataById = host+'/register/get';
export const EditBookingDataById = host+'/register/edit';
export const DeleteBookingDataById = host+'/register/delete';
export const GetUserRoles = host+'/role';
export const getpermitions = host+'/getpermissions';
export const addUser = host+'/useradd';
export const getManagerData= host+'/managerData';
export const getTlData= host+'/teamleaderData';
export const getUserDataByToken = host+'/getuserinfo';

export const getCatagories = host+'/getallcategoriesbyproject';
export const getProjects = host+'/getallprojects';

export const addBlogs = host+'/creedblog';
export const getAllBlog = host+'/blog/fetch';
export const GetBlogsDataById = host+'/blog/getSingleAdminBlog/';
export const UpdateBlogData = host+'/blog/blog_update';
export const DeleteBlogData = host+'/blog/blog_delete';
export const UploadBlogBodyImage= host+'/bodyimageupload';
export const UploadFeatureImageUrl= host+'/upload_feature_image';

//  job api
export const getAllJob = host+'/getjob';
export const postJob = host+'/jobcreate';
export const updateJob = host+'/updatejob';
export const getjobdatabyid = host+'/getjobbyid';
export const UploadJobIcon = host+'/api/jobiconupload';

export const GetJobDataById = host+'/job/getSingleAdminJob/';
export const DeleteJobData = host+'/job/job_delete';

// servay api's
export const  singleSurveyDataById = host+'/api/singlesurvaydata';
export const  surveyDataAllPercentageAndCount = host+'/api/getalloveraverage';
export const  surveyquestionsdata = host+'/api/geteveryquestionsanswerpercentage';
export const  surveydatabyuser = host+'/api/getalldatabyuser';
