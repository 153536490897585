
import React, { useEffect } from "react";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart } from "react-icons/md";

import Widget from "components/widget/Widget";
import DailyTraffic from "views/admin/default/components/DailyTraffic";

function disableBackButton() {
  window.history.pushState(null, null, window.location.pathname);
  window.addEventListener("popstate", function (event) {
    window.history.pushState(null, null, window.location.pathname);
  });
}
const Dashboard = () => {
  useEffect(() => {
    disableBackButton();
  }, []);
  return (
    <div>
      {/* Card widget */}
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Earnings"}
          subtitle={"$340.5"}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Spend this month"}
          subtitle={"$642.39"}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Sales"}
          subtitle={"$574.34"}
        />
      </div>
      <div className="mt-4 grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
        <DailyTraffic />
        <DailyTraffic />
      </div>
      

      {/* Charts */}

   
    </div>
  );
};

export default Dashboard;
