import React, { useEffect, useState } from "react";
import { singleSurveyDataById } from "proxyUrl";
import axios from "axios";

export default function SingleSurvey() {
  const [data, setData] = useState("");
  console.log(data);
  const getData = async () => {
    await axios
      .get(singleSurveyDataById)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {data ? (
        <div className="mx-auto  mt-4 min-h-[83vh] w-full rounded-lg bg-white p-6 shadow">
          <div className=" flex gap-10">
            <div className="min-h-[300px]  w-[50%] rounded-md   p-3 shadow-md">
              <h2 className="mb-4 text-2xl font-semibold">User Information</h2>
              <div className=" flex flex-col gap-3 pt-5 ">
                <p>
                  <strong>User Id:</strong> {data?.[0].id}
                </p>
                <p>
                  <strong>Browser Id:</strong> {data?.[0].user_id}
                </p>
                <p>
                  <strong>Department Id:</strong> {data?.[0].department}
                </p>
                <p>
                  <strong>Message Id:</strong> {data?.[0].message}
                </p>
              </div>
            </div>
            <div className=" py-5 px-3  min-h-[300px] w-[50%] items-center justify-center rounded-md shadow-md ">
             <h1 className=" text-2xl font-semibold" >User Feadback</h1>
              <div className="flex items-start mt-10 h-full justify-center gap-4">
              <div className="  flex h-[100px] w-[100px] flex-col items-center justify-center  rounded-md  shadow-md">
                {" "}
                <h1>Strongagree</h1> <p>40%</p>{" "}
              </div>
              <div className="  flex h-[100px] w-[100px] flex-col items-center justify-center  rounded-md shadow-md ">
                <h1>Agree</h1> <p>40%</p>{" "}
              </div>
              <div className="  flex h-[100px] w-[100px] flex-col items-center justify-center  rounded-md  shadow-md">
                <h1>Disagree</h1> <p>40%</p>{" "}
              </div>
              <div className="  flex h-[100px] w-[100px] flex-col items-center justify-center  rounded-md  shadow-md">
                <h1>Strongagree</h1> <p>40%</p>{" "}
              </div>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <h2 className="mb-4 text-2xl font-semibold">Survey Question</h2>
            {data.map((data, index) => {
              return (
                <>
                  <p className="mb-2">{data.question_id}</p>
                  <p className="font-semibold text-green-500">{data.answer}</p>
                </>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
