import { useState, useEffect } from "react";
import { getAllJob } from "proxyUrl";
import JobTable from "./JobTable";
import { Link } from "react-router-dom";
import axios from "axios";
const Job = () => {
  const [blogData, setBlogData] = useState("");
  const fetchData = () => {
    axios
      .get(getAllJob, {
        withCredentials: true,
      })
      .then((res) => {
        return res;
      })
      .then((blogdata) => {
        const blogData = blogdata.data;
        setBlogData(blogData);
      });
  };
useEffect(() =>{
    fetchData()
},[])
  return (
    <>
      <div className=" add-booking-header  relative  mx-4 mt-6 flex justify-between rounded-2xl bg-primaryBlue p-3 shadow-lg dark:bg-navy-800">
        <div className="text-xl font-bold text-white  dark:text-white">
          All Posted Jobs
        </div>
        <Link to="/admin/jobs/create">
          <button className="rounded-full bg-white p-1  px-3 text-navy-800 dark:bg-navy-900 dark:text-white">
            Post New Job
          </button>
        </Link>
      </div>
      <div className="relative -mt-7  grid w-full grid-cols-1 gap-5 md:grid-cols-1">
        {blogData ? (
          <>
          <JobTable
            columnLenghth={blogData.length}
            tableData={blogData}
            editRoute={`/admin/jobs/edit`}
            deleteRoute={`/admin/jobs/delete`}
          />
        </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Job;
