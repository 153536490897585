/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { HiX } from "react-icons/hi";
import Links from "./componentsrtl/Links";
import "./animation.css";
import SidebarCard from "./componentsrtl/SidebarCard";
// import routes from "../../admin_routes.js";

import { useSelector } from "react-redux";
import axios from "axios";
import { getUserDataByToken } from "proxyUrl";
import { Link } from "react-router-dom";

const Sidebar = ({ open, onClose, routes }) => {
  // const [navBack, setNavBack] = useState("sidebar-Wrapper");
  const theme = useSelector((state) => state.theme);
  const [user, setUser] = useState("");

  const userDataByApi = async () => {
    await axios
      .get(getUserDataByToken, {
        withCredentials: true,
      })
      .then((res) => {
        setUser(res.data.user.userInfo.email);
      });
  };
  useEffect(() => {
    if (theme.isDark) {
      // setNavBack("sidebar-WrapperDark")
    } else {
      // setNavBack("sidebar-Wrapper")
    }
  }, [theme.isDark]);

  useEffect(() => {
    userDataByApi();
  }, []);
  return (
    <>
      {user === "andy@creedstaffing.com" ? (
        <>
          <div
            className={`sm:none  duration-175 shadow-white/9  fixed   !z-50  m-4 flex min-h-[96vh] flex-col overflow-hidden rounded-xl bg-primaryBlue px-6  pb-10 shadow-2xl transition-all dark:bg-navy-800  dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
              open ? "translate-x-0" : "-translate-x-96"
            }`}
          >
            <span
              className="absolute top-4 right-4 block cursor-pointer xl:hidden"
              onClick={onClose}
            >
              <HiX />
            </span>
            <div
              className={`mx-[56px] mt-[50px] flex items-start justify-start`}
            >
              <div className="mt-1 ml-1 h-1 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
                {/* Creed_Infotech <span className="font-medium">TRAVAL</span> */}
                <img
                  className="mt-[-30px]"
                  src="/logocr.png"
                  alt=""
                  width={120}
                />
              </div>
            </div>
            <div className="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
            {/* Nav item */}
            <ul className="mb-auto pt-1">
              <Link to={"/admin/dashboard"} className="relative mb-3 flex hover:cursor-pointer hover:text-dark px-3  py-2 rounded-md duration-200 hover:bg-lightPrimary font-medium text-white">Dashboard</Link>
              <Link to={"/admin/jobs"} className="relative mb-3 flex hover:cursor-pointer hover:text-dark px-3  py-2 rounded-md duration-200 hover:bg-lightPrimary font-medium text-white">All Jobs</Link>
              <Link to={"/auth/sign-out"} className="relative mb-3 flex hover:cursor-pointer hover:text-dark px-3  py-2 rounded-md duration-200 hover:bg-lightPrimary font-medium text-white">Logout</Link>
            </ul>
            {/* Free Horizon Card */}
            <div className="flex justify-center">
              <SidebarCard />
            </div>
            {/* Nav item end */}
            <div className="navbar-flight absolute top-[73%] left-0 mx-auto flex h-[200px] w-[100%]  items-center justify-center p-4">
              <div className=" mx-auto h-full w-full    rounded-xl">
                {" "}
                <img
                  src="/myimage.png"
                  className=" h-full w-full object-cover"
                  alt=""
                />
              </div>
            </div>
            <div className="area -z-3 absolute left-0 top-0 h-full w-full ">
              <ul className="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`sm:none  duration-175 shadow-white/9  fixed   !z-50  m-4 flex min-h-[96vh] flex-col overflow-hidden rounded-xl bg-primaryBlue px-6  pb-10 shadow-2xl transition-all dark:bg-navy-800  dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
              open ? "translate-x-0" : "-translate-x-96"
            }`}
          >
            <span
              className="absolute top-4 right-4 block cursor-pointer xl:hidden"
              onClick={onClose}
            >
              <HiX />
            </span>
            <div
              className={`mx-[56px] mt-[50px] flex items-start justify-start`}
            >
              <div className="mt-1 ml-1 h-1 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
                {/* Creed_Infotech <span className="font-medium">TRAVAL</span> */}
                <img
                  className="mt-[-30px]"
                  src="/logocr.png"
                  alt=""
                  width={120}
                />
              </div>
            </div>
            <div className="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
            {/* Nav item */}
            <ul className="mb-auto pt-1">
              <Links routes={routes} />
            </ul>
            {/* Free Horizon Card */}
            <div className="flex justify-center">
              <SidebarCard />
            </div>
            {/* Nav item end */}
            <div className="navbar-flight absolute top-[73%] left-0 mx-auto flex h-[200px] w-[100%]  items-center justify-center p-4">
              <div className=" mx-auto h-full w-full    rounded-xl">
                {" "}
                <img
                  src="/myimage.png"
                  className=" h-full w-full object-cover"
                  alt=""
                />
              </div>
            </div>
            <div className="area -z-3 absolute left-0 top-0 h-full w-full ">
              <ul className="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Sidebar;
