import React, { useState, useEffect } from "react";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import {
  surveyDataAllPercentageAndCount,
  surveydatabyuser,
  surveyquestionsdata,
} from "proxyUrl";
import axios from "axios";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import { Link } from "react-router-dom";
export default function Survey() {
  const [surveyCount, setSurveyCount] = useState("");
  const [setSurveyPercentage] = useState("");
  const [ChartArrayDataFromApi, setChartArrayDataFromApi] = useState("");
  const [weekellyDtataFromApi, setWeekellyDtataFromApi] = useState("");
  const [dataSurveyByUsers, setDataSurveyByUsers] = useState([]);

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const TableColAnswer = (Array, col) => {
    const foundItem = Array.find((item) => item.name === col);

    const jsxToRender = foundItem ? (
      <td className="px-3 py-2">
        <span> {Math.trunc(Number(foundItem.average))}% </span>
      </td>
    ) : (
      <td className="px-3 py-2">
        <span> - </span>
      </td>
    );

    return jsxToRender;
  };

  const getSurveyData = async () => {
    try {
      await axios.get(surveyDataAllPercentageAndCount).then((res) => {
        console.log(res,8765);
        setSurveyCount(res.data.data.count);
        setSurveyPercentage(res.data.data.percentage);
        setChartArrayDataFromApi(res.data.data.percentage.percentage_array);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getSurveyDataByUser = async () => {
    try {
      await axios.get(surveydatabyuser).then((res) => {
        setDataSurveyByUsers(res.data.data);
        console.log(res.data.data, 87654);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getWeekellySurveyData = async () => {
    try {
      await axios.get(surveyquestionsdata).then((res) => {
        console.log(res,"datatat");
        const data = [
          {
            name: "AGREE",
            data: res.data.data.agree,
            color: "#05BFDB",
          },
          {
            name: "DISAGREE",
            data: res.data.data.disagree,
            color: "#116D6E",
          },
          {
            name: "NITHER",
            data: res.data.data.nither,
            color: "#7E1717",
          },
          {
            name: "STRONG AGREE",
            data: res.data.data.strong_agree,
            color: "#11009E",
          },
          {
            name: "STRONG DISAGREE",
            data: res.data.data.strong_disagree,
            color: "#CD1818",
          },
        ];
        setWeekellyDtataFromApi(data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSurveyData();
    getWeekellySurveyData();
    getSurveyDataByUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // <---------------------------- Pie Chart Starts ------------------------>//

  const surveyChartOption = {
    labels: ["Agree", "Disagree", "Nither", "Strong Agree", "Strong Disagree"],
    colors: ["#00DFA2", "#FF52A2", "#F31559", "#00FFCA", "#ED2B2A"],
    chart: {
      width: "50px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: true,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    fill: {
      colors: ["#00DFA2", "#FF52A2", "#F31559", "#00FFCA", "#ED2B2A"],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      style: {
        fontSize: "12px",
        fontFamily: undefined,
        backgroundColor: "#000000",
      },
    },
  };

  // <---------------------------- Pie Chart Ends ---------------------------->//

  //  <--------------------------- BarChart Starts ----------------------------> //
  const barChartOption = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    // colors:['#ff3322','#faf']
    tooltip: {
      style: {
        fontSize: "15px",
        fontFamily: undefined,
        backgroundColor: "#000000",
      },
      theme: "dark",
      onDatasetHover: {
        style: {
          fontSize: "14px",
          fontFamily: undefined,
        },
      },
    },
    xaxis: {
      categories: [
        "Management keeps me informed about important issues and changes.",
        "Management is approachable, easy to talk with.",
        "Management is competent at running the business.",
        "Management does a good job of assigning and coordinating people.",
        "Management trusts people to do a good job without watching over their shoulders.",
        "Management has a clear view of where the organization is going and how to get there.",
        "Management delivers on its promises.",
        "Management trusts people to do a good job without watching over their shoulders.Management is honest and ethical in its business practices.  ",
        "I am offered training or development to further myself professionally.",
        "Management shows appreciation for good work and extra effort.",
        "Management genuinely seeks and responds to suggestions and ideas.",
        "This is a psychologically and emotionally healthy place to work.",
        "People are encouraged to balance their work life and their personal life.",
        "Management shows a sincere interest in me as a person, not just an employee.",
        "People here are paid fairly for the work they do.",
        "I am given the resources and equipment to do my job.I am treated as a full member here regardless of my position.",
        "Management shows appreciation for good work and extra effort.",
        "If I am unfairly treated, I believe I'll be given a fair hearing if I appeal.",
        "My work has special meaning: this is not 'just a job'.",
        "People here are willing to put in extra effort to get the job done.",
        "I want to work here for a long time.",
        "People care about each other here.",
        "This is a friendly place to work.",
        "When you join the organization, you are made to feel welcome.",
        "There is a family or team feeling here.",
        "You can count on people to cooperate.",
        "Management does a good job of developing managers for leadership positions.",
        "I understand how my role contributes to the organization's goals.",
        "Performance of employees here is fairly evaluated.",
        "Taking everything into account, I would say this is a great place to work.",
      ],
      show: true,
      labels: {
        show: false,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: "black",
      labels: {
        show: false,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },
    grid: {
      borderColor: "rgba(163, 174, 208, 0.3)",
      show: true,
      yaxis: {
        lines: {
          show: false,
          opacity: 0.5,
        },
      },
      row: {
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "solid",
      colors: ["#00DFA2", "#6AD2FF", "#E1E9F8"],
    },
    legend: {
      show: true,
    },
    colors: ["#00DFA2", "#6AD2FF", "#E1E9F8"],
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        columnWidth: "50px",
      },
    },
  };
  const barData = [
    {
      name: "AGREE",
      data: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      color: "#00DFA2",
    },
    {
      name: "DISAGREE",
      data: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      color: "#116D6E",
    },
    {
      name: "NITHER",
      data: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      color: "#7E1717",
    },
    {
      name: "STRONG AGREE",
      data: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      color: "#11009E",
    },
    {
      name: "STRONG DISAGREE",
      data: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      color: "#CD1818",
    },
  ];
  const labelData = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];
  //  <--------------------------- BarChart Ends ----------------------------> //
  return (
    <>
      <div className="container mx-auto mt-3 rounded-lg   dark:bg-navy-900 dark:text-gray-100 sm:p-4">
        <div className=" flex h-14 w-full items-center justify-end rounded-lg bg-primaryBlue p-2 ">
          <button
            onClick={onDownload}
            className=" text-slate-800 rounded-lg bg-white px-4 py-1"
          >
            {" "}
            Generate Report{" "}
          </button>
        </div>

        <div>
          <table ref={tableRef} className="min-w-full text-xs ">
            <thead className=" bg-primaryBlue  text-white  dark:bg-navy-900  ">
              <tr className="text-right">
                <th
                  title="Quetion Id"
                  className=" rounded-l-md  p-3  pl-0 text-right"
                >
                  User Id
                </th>
                <th title="Losses" className="p-3  text-right">
                  Strongly Agree
                </th>
                <th title="Wins" className="p-3   text-right">
                  Agree
                </th>

                <th title="Games behind" className="p-3  text-right">
                  Neither Agree nor Disagree
                </th>
                <th title="Home games" className="p-3  text-right">
                  Disagree
                </th>
                <th title="Home games" className="p-3  text-right">
                  Strongly Disagree
                </th>
                <th title="Team name" className="p-3 text-right ">
                  Department
                </th>
                <th title="Away games" className="rounded-r-md p-3  text-right">
                  Message
                </th>
              </tr>
            </thead>
            <tbody>
              {dataSurveyByUsers[0] ? (
                <>
                  {dataSurveyByUsers.map((data, index) => {
                    const userCount = index + 1;
                    const AnswersData = data.answers;
                    return (
                      <>
                        <tr className="border-b border-opacity-20 text-right dark:border-gray-700 dark:bg-navy-800">
                          <td className="px-3 py-2 text-right">
                            <span>{userCount}</span>
                          </td>

                          {TableColAnswer(AnswersData, "Strongagree")}
                          {TableColAnswer(AnswersData, "agree")}
                          {TableColAnswer(AnswersData, "nither")}
                          {TableColAnswer(AnswersData, "disagree")}
                          {TableColAnswer(AnswersData, "strongdis")}

                          <td className="px-3 py-2 text-right">
                            <span>{data.department}</span>
                          </td>
                          <td className="px-3 py-2">
                            <span>{data.message}</span>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
        <div className=" mt-20 mb-10 min-h-[300px] w-full ">
          <div className="h-full">
            <PieChartCard
              counts={surveyCount}
              text={""}
              data={ChartArrayDataFromApi}
              option={surveyChartOption}
            />
          </div>
        </div>
        <div className=" mb-10 min-h-[300px] w-full ">
          <div className="relative h-full">
            {weekellyDtataFromApi ? (
              <>
                <WeeklyRevenue
                  option={barChartOption}
                  graphData={
                    weekellyDtataFromApi ? weekellyDtataFromApi : barData
                  }
                  counts={surveyCount}
                />
                <div className="    absolute bottom-12 left-6 flex w-[95.6%] justify-between">
                  {labelData.map((data, index) => (
                    <>
                      <p className="  flex w-[20px] items-center justify-center">
                        {data}
                      </p>
                    </>
                  ))}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        
      </div>
    </>
  );
}
