/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { getUserDataByToken } from "proxyUrl";
import axios from "axios";

const pageSize = 10; // Number of items per page

export default function JobTable(props) {
  const { tableData, editRoute, deleteRoute } = props;
  const [user, setUser] = useState("");

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total number of pages
  const totalPages = Math.ceil(tableData.length / pageSize);

  // Calculate the index range of the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  // Get the current page's data
  const currentData = tableData.slice(startIndex, endIndex);

  // Handle previous page
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const userDataByApi = async () => {
    await axios
      .get(getUserDataByToken, {
        withCredentials: true,
      })
      .then((res) => {
        setUser(res.data.user.userInfo.email);
      });
  };

  useEffect(() => {
    userDataByApi();
  }, []);

  // Handle next page
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <>
      <section className="container mx-auto px-4 ">
        <div className=" flex flex-col">
          <div className="-mx-4  -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className=" overflow-hidden border border-gray-200 dark:border-navy-700 dark:bg-navy-900 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-navy-700 dark:border-navy-700 dark:bg-navy-900">
                  <thead className="bg-gray-50  dark:bg-navy-900">
                    <tr>
                      <th
                        scope="col"
                        className="text-md font-sm py-3.5 px-4 text-left text-gray-700 rtl:text-right dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>Image</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className=" text-md font-sm py-3.5 text-left text-gray-700 rtl:text-right dark:text-gray-400"
                      >
                        <span>Title</span>
                      </th>
                      <th
                        scope="col"
                        className=" text-md font-sm py-3.5 text-left text-gray-700 rtl:text-right dark:text-gray-400"
                      >
                        <span>Type</span>
                      </th>
                      <th
                        scope="col"
                        className="text-lefttext-md font-sm px-4 py-3.5 text-gray-700 rtl:text-right dark:text-gray-400"
                      >
                        <span>Salary</span>
                      </th>
                      <th
                        scope="col"
                        className="text-md font-sm w-[100px] px-4 py-3.5 text-left text-gray-700 rtl:text-right dark:text-gray-400"
                      >
                        Skills
                      </th>

                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-sm font-normal text-gray-500 rtl:text-right dark:text-gray-400"
                      >
                        <span className=""></span>
                      </th>
                      <th
                        scope="col"
                        className="text-md font-sm px-4 py-3.5 text-left text-gray-700 rtl:text-right dark:text-gray-400"
                      >
                        <span className="">Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white dark:divide-navy-700  dark:bg-navy-900">
                    {user === "andy@creedstaffing.com" ? (
                      <>
                        {tableData ? (
                          <>
                            {currentData.map((data, index) => (
                              <>
                                {data.type === 2 && ( 
                                  <tr key={index}>
                                    <td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
                                      <div className="inline-flex items-center gap-x-3">
                                        <div className="flex items-center gap-x-2">
                                          <div>
                                            <h2 className="font-medium text-gray-800 dark:text-white ">
                                              <img src={data.image} alt="" />
                                            </h2>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300">
                                      {data.title}
                                    </td>
                                    <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300">
                                      {data.type}
                                    </td>
                                    <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300">
                                      {data.salary}
                                    </td>
                                    <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300">
                                      {data.status === "1" ? (
                                        <div className="flex items-center justify-center rounded-full bg-red-400 px-4 py-1 text-white duration-300 hover:bg-red-300">
                                          <p>disabled</p>
                                        </div>
                                      ) : (
                                        <div className="flex items-center justify-center rounded-full bg-green-600 px-4 py-1 text-white duration-200 hover:bg-green-500">
                                          <p>active</p>
                                        </div>
                                      )}
                                    </td>
                                    <td className="whitespace-nowrap px-4 py-4 text-sm"></td>
                                    <td className="whitespace-nowrap px-4 py-4 text-sm">
                                      <div className="flex items-center gap-x-6">
                                        <Link to={`${deleteRoute}/${data.id}`}>
                                          <button className="text-gray-500 transition-colors duration-200 hover:text-red-500 focus:outline-none dark:text-gray-300 dark:hover:text-red-500">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth="1.5"
                                              stroke="currentColor"
                                              className="h-5 w-5"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                              />
                                            </svg>
                                          </button>
                                        </Link>
                                        <Link to={`${editRoute}/${data.id}`}>
                                          <button className="text-gray-500 transition-colors duration-200 hover:text-yellow-500 focus:outline-none dark:text-gray-300 dark:hover:text-yellow-500">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth="1.5"
                                              stroke="currentColor"
                                              className="h-5 w-5"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                              />
                                            </svg>
                                          </button>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            ))}
                          </>
                        ) : (
                          <>Loading Data</>
                        )}
                      </>
                    ) : (
                      <>
                        {tableData ? (
                          <>
                            {currentData.map((data, index) => (
                              <>
                                <tr>
                                  <td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
                                    <div className="inline-flex items-center gap-x-3">
                                      <div className="flex items-center gap-x-2">
                                        <div>
                                          <h2 className="font-medium text-gray-800 dark:text-white ">
                                            <img src={data.image} alt="" />
                                          </h2>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className=" px-4 py-4 text-sm text-gray-500 dark:text-gray-300">
                                    {data.title}
                                  </td>
                                  <td className=" px-4 py-4 text-sm text-gray-500 dark:text-gray-300">
                                    {data.type}
                                  </td>
                                  <td className=" px-4 py-4 text-sm text-gray-500 dark:text-gray-300">
                                    {data.salary}
                                  </td>
                                  <td className=" px-4  py-4 text-sm text-gray-500 dark:text-gray-300">
                                    {data.status === "1" ? (
                                      <>
                                        {" "}
                                        <div className=" flex items-center justify-center rounded-full bg-red-400 px-4 py-1 text-white duration-300 hover:bg-red-300  ">
                                          <p>disabled</p>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        <div className=" flex items-center justify-center rounded-full bg-green-600 px-4 py-1 text-white duration-200 hover:bg-green-500  ">
                                          <p>active</p>
                                        </div>
                                      </>
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap px-4 py-4 text-sm"></td>
                                  <td className="whitespace-nowrap px-4 py-4 text-sm">
                                    <div className="flex items-center gap-x-6">
                                      <Link to={`${deleteRoute}/${data.id}`}>
                                        <button className="text-gray-500 transition-colors duration-200 hover:text-red-500 focus:outline-none dark:text-gray-300 dark:hover:text-red-500">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="h-5 w-5"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                            />
                                          </svg>
                                        </button>
                                      </Link>
                                      <Link to={`${editRoute}/${data.id}`}>
                                        <button className="text-gray-500 transition-colors duration-200 hover:text-yellow-500 focus:outline-none dark:text-gray-300 dark:hover:text-yellow-500">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="h-5 w-5"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                            />
                                          </svg>
                                        </button>
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </>
                        ) : (
                          <>Loading Data</>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end gap-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="flex items-center gap-x-2 rounded-md border bg-white px-5 py-2 text-sm capitalize text-gray-700 transition-colors duration-200 hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-5 w-5 rtl:-scale-x-100"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
              />
            </svg>
            <span>previous</span>
          </button>

          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="flex items-center gap-x-2 rounded-md border bg-white px-5 py-2 text-sm capitalize text-gray-700 transition-colors duration-200 hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800"
          >
            <span>Next</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-5 w-5 rtl:-scale-x-100"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </button>
        </div>
      </section>
    </>
  );
}
