import React, { useState, useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsFillImageFill } from "react-icons/bs";
import { getjobdatabyid, updateJob, UploadJobIcon } from "proxyUrl";
import { MultiSelect } from "react-multi-select-component";
import { getUserDataByToken } from "proxyUrl";

const QualificationOption = [
  { value: "B.Tech", label: "B.Tech", disabled: false },
  { value: "MCA", label: "MCA", disabled: false },
  { value: "BCA", label: "BCA", disabled: false },
  { value: "M.Tech", label: "M.Tech", disabled: false },
  { value: "Diploma", label: "Diploma", disabled: false },
  { value: "MBA", label: "MBA", disabled: false },
];
const shiftOption = [
  {
    value: "Morning(10AM - 7PM)",
    label: "Morning(10AM - 7PM)",
    disabled: false,
  },
  { value: "Evening(5PM - 2AM)", label: "Evening(5PM - 2AM)", disabled: false },
];
const LocationOption = [
  { value: "Remote", label: "Remote", disabled: false },
  { value: "On Site", label: "On Site", disabled: false },
];

const ExperienceOption = [
  { value: "Fresher", label: "Fresher", disabled: false },
  { value: "0-1 year", label: "0-1 year", disabled: false },
  { value: "1-2 years", label: "1-2 years", disabled: false },
  { value: "2-3 years", label: "2-3 years", disabled: false },
  { value: "Abover 5 years", label: "Abover 5 years", disabled: false },
];

export default function UpdateJob() {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [preJobData, setPreJObData] = useState("");

  const [jobData, setJobData] = useState({
    title: preJobData ? preJobData.title : "",
    type: "",
    metadescription: "",
    salary: "",
    about: "",
    metakeywords: "",
    metatitle: "",
    alttext: "",
    vacancy: "",
    shortDescription: "",
    skills: "",
    location: "",
    shift: "",
    qualification: "",
    requirements: "",
    slug: "",
  });
  const [Location, setLocation] = useState([]);
  const [Experience, setExperience] = useState([]);
  const [shift, setShift] = useState([]);
  const [qualification, setQualification] = useState([]);

  const [image, setImage] = useState();
  const [preVieousImage, setPreviousImage] = useState();
  console.log(preVieousImage);
  const [status, setStatus] = useState("");
  const [category, setCategory] = useState("");
  const [preview, setPreview] = useState(null);
  const [submiteed, setSubmiteed] = useState(false);
  const [user, setUser] = useState("");

  const userDataByApi = async () => {
    await axios
      .get(getUserDataByToken, {
        withCredentials: true,
      })
      .then((res) => {
        setUser(res.data.user.userInfo.email);
      });
  };

  useEffect(() => {
    userDataByApi();
  }, []);

  console.log(submiteed);
  const HandleStatus = (e) => {
    setStatus(e.target.value);
  };
  const HandleCategory = (e) => {
    setCategory(e.target.value);
  };

  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  const getJobData = async () => {
    await axios.get(`${getjobdatabyid}/${jobId}`).then((res) => {
      if (res.status === 200) {
        setPreJObData(res.data.data);
      } else {
        setPreJObData(null);
      }
    });
  };
  useEffect(() => {
    getJobData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setJobData({
      ...jobData,
      title: preJobData ? preJobData.title : "",
      type: preJobData ? preJobData.type : "",
      metadescription: preJobData ? preJobData.metadescription : "",
      salary: preJobData ? preJobData.salary : "",
      about: preJobData ? preJobData.about : "",
      metakeywords: preJobData ? preJobData.metakeywords : "",
      metatitle: preJobData ? preJobData.metatitle : "",
      alttext: preJobData ? preJobData.alttext : "",
      vacancy: preJobData ? preJobData.vacancy : "",
      shortDescription: preJobData ? preJobData.shortDescription : "",
      skills: preJobData ? preJobData.skills : "",
      location: preJobData ? preJobData.location : "",
      shift: preJobData ? preJobData.shift : "",
      qualification: preJobData ? preJobData.qualification : "",
      requirements: preJobData ? preJobData.requirements : "",
      slug: preJobData ? preJobData.slug : "",
    });
    setPreviousImage(preJobData ? preJobData.image : "");
    setQualification(preJobData ? preJobData.qualification : "");
    setExperience(preJobData ? preJobData.experience : "");
    setShift(preJobData ? preJobData.shift : "");
    setLocation(preJobData ? preJobData.location : "");
    setStatus(preJobData ? preJobData.status : "");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preJobData]);
  useEffect(() => {
    if (!image) {
      setPreview(preVieousImage);
    }
  }, [image, preVieousImage]);
  const sendData = async (e) => {
    e.preventDefault();
    const IconNewUrl = [];
    if (image) {
      const formData = new FormData();
      formData.append("icon", image);
      await axios
        .post(UploadJobIcon, formData, {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data.imageUrl, "log");
            IconNewUrl.push(res.data.imageUrl);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    //creating form data
    await axios
      .post(
        `${updateJob}/${jobId}`,
        {
          title: jobData.title,
          type: jobData.type,
          slug: jobData.slug,
          category: category,
          metakeywords: jobData.metakeywords,
          metadescription: jobData.metadescription,
          metatitle: jobData.metatitle,
          alttext: jobData.alttext,
          salary: jobData.salary,
          about: jobData.about,
          status: status,
          vacancy: jobData.vacancy,
          experience: Experience,
          image: IconNewUrl[0] ? IconNewUrl[0] : preVieousImage,
          shortDescription: jobData.shortDescription,
          skills: jobData.skills,
          location: Location,
          shift: shift,
          qualification: qualification,
          requirements: jobData.requirements,
        },

        {
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log(res.status);
        if (res.status === 200) {
          console.log("Form submiteed succsesfully");
          toast.success("Form submiteed succsesfully!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          const delayfrfresh = () => {
            navigate("/admin/jobs");
          };
          // eslint-disable-next-line
          const delayRef = setTimeout(delayfrfresh, 3100);
        }
      })
      .catch((err) => {
        console.log(err.response.status);
        if (err.response.status === 400) {
          console.log("Please fill all data");
          toast.error("Please fill all data!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          const delay = () => {
            setSubmiteed(false);
          };
          // eslint-disable-next-line
          const myTimeout = setTimeout(delay, 3000);
        } else {
          console.log("something went wrong");
          toast.error("something went wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          const delay = () => {
            setSubmiteed(false);
          };
          // eslint-disable-next-line
          const myTimeout = setTimeout(delay, 3000);
        }
      });
  };

  let name, value;
  const HandleInput = (e) => {
    name = e.target.name;
    value = e.target.value;
    setJobData({ ...jobData, [name]: value });
  };
  console.log(preview, 65456);
  return (
    <>
      <div className="mt-4 min-h-screen   w-full rounded-md py-10  ">
        <form className="mx-auto w-[90%] " action="">
          <div className=" job_field_wrapper  ">
            <div className="job_field">
              <label htmlFor="title">Job Title</label>
              <input
                onChange={HandleInput}
                value={jobData.title}
                type="text"
                className="job_input"
                name="title"
                id="title"
              />
            </div>
            <div className="job_field">
              <label htmlFor="slug">Slug</label>
              <input
                onChange={HandleInput}
                type="text"
                className="job_input"
                name="slug"
                value={jobData.slug}
                id="slug"
              />
            </div>
            <div className="job_field">
              <label htmlFor="type">Type</label>
              <input
                onChange={HandleInput}
                type="text"
                className="job_input"
                name="type"
                placeholder="Creed Infotech-1, Creed Staffing-2"
                value={user === "andy@creedstaffing.com" ? 2 : 1}
                id="type"
                disabled
              />
            </div>
          </div>
          <div className=" job_field_wrapper  ">
            <div className="job_field">
              <label htmlFor="salary">Salary</label>
              <input
                onChange={HandleInput}
                value={jobData.salary}
                type="text"
                className="job_input"
                id="salary"
                name="salary"
              />
            </div>
            <div className="job_field">
              <label htmlFor="about">About Job</label>
              <input
                onChange={HandleInput}
                type="text"
                className="job_input"
                name="about"
                value={jobData.about}
                id="about"
              />
            </div>
          </div>
          <div className=" job_field_wrapper  ">
            <div className="job_field">
              <label htmlFor="category">Qualification </label>
              <MultiSelect
                options={QualificationOption}
                value={qualification}
                onChange={setQualification}
                data={qualification}
                labelledBy="Select"
                className=" mt-2"
              />
            </div>
            <div className="job_field">
              <label htmlFor="shift">Shift</label>
              <MultiSelect
                options={shiftOption}
                value={shift}
                onChange={setShift}
                data={shift}
                labelledBy="Select"
                className=" mt-2"
              />
            </div>
          </div>

          <div className=" job_field_wrapper  ">
            <div className="job_field">
              <label htmlFor="experience">Experience </label>
              <MultiSelect
                options={ExperienceOption}
                value={Experience}
                onChange={setExperience}
                data={Experience}
                labelledBy="Select"
                id="experience"
                className=" mt-2"
              />
            </div>
            <div className="job_field">
              <label htmlFor="shift">Location</label>
              <MultiSelect
                options={LocationOption}
                value={Location}
                onChange={setLocation}
                data={Location}
                labelledBy="Select"
                className=" mt-2"
              />
            </div>
          </div>

          <div className="job_field_wrapper">
            <div className="job_field">
              <label htmlFor="requirements">Requirements</label>
              <input
                onChange={HandleInput}
                type="text"
                value={jobData.requirements}
                className="job_input"
                id="requirements"
                name="requirements"
              />
            </div>
            <div className="job_field">
              <label htmlFor="vacancy">Vacancy </label>
              <input
                onChange={HandleInput}
                value={jobData.vacancy}
                type="number"
                min="1"
                max="100"
                name="vacancy"
                className="job_input"
                id="vacancy"
              />
            </div>
          </div>
          <div className="job_field_wrapper">
            <div className="job_field">
              <label htmlFor="skills">Skills </label>
              <input
                onChange={HandleInput}
                value={jobData.skills}
                type="text"
                className="job_input"
                name="skills"
                id="skills"
              />
            </div>
            <div className="job_field">
              <label htmlFor="category">Category </label>
              <select
                onChange={HandleCategory}
                name="status"
                className="input_select"
                id="status"
              >
                <option value="NONE">NONE </option>
                <option value="SEO">SEO </option>
                <option value="DEVELOPER">DEVELOPER </option>
                <option value="DIGITAL MARKETING">DIGITAL MARKETING </option>
                <option value="TUTORING">TUTORING</option>
              </select>
            </div>
          </div>

          <div className=" job_field_wrapper  ">
            <div className="job_field">
              <label htmlFor="metatitle">Meta Title</label>
              <input
                onChange={HandleInput}
                type="text"
                className="job_input"
                name="metatitle"
                value={jobData.metatitle}
                id="metatitle"
              />
            </div>
            <div className="job_field">
              <label htmlFor="metadescription">Meta Description</label>
              <input
                onChange={HandleInput}
                value={jobData.metadescription}
                type="text"
                className="job_input"
                name="metadescription"
                id="metadescription"
              />
            </div>
          </div>

          <div className="job_field_wrapper">
            {" "}
            <div className="job_field">
              <label htmlFor="metakeywords">Meta Keywords </label>
              <input
                onChange={HandleInput}
                value={jobData.metakeywords}
                type="text"
                className="job_input"
                id="metakeywords"
                name="metakeywords"
              />
            </div>
            <div className="job_field">
              <label htmlFor="shortDescription">Short Description</label>
              <input
                onChange={HandleInput}
                type="text"
                className="job_input"
                value={jobData.shortDescription}
                name="shortDescription"
                id="shortDescription"
              />
            </div>
          </div>

          {/* //image field */}

          <div className=" my-6 flex gap-3">
            <div className="input relative mr-7  flex w-[50%] items-center justify-center rounded-lg border-2 border-blueSecondary   ">
              <div className=" h-[200px] w-[400px] rounded-lg ">
                {preview ? (
                  <img
                    className="h-full w-full rounded-md object-cover "
                    src={preview}
                    alt=""
                  />
                ) : (
                  <>
                    <div className=" flex h-full w-full items-center justify-center text-8xl text-primaryBlue ">
                      <BsFillImageFill />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="   flex w-[50%] flex-col gap-5  ">
              <div className="input relative">
                <label htmlFor="alttext">
                  {" "}
                  Image or icon releted to the job
                </label>
                <input
                  type="file"
                  name="file"
                  // value={jobData.alttext}
                  onChange={handleImage}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="alttext">Alttext </label>
                <input
                  onChange={HandleInput}
                  value={jobData.alttext}
                  type="text"
                  className="job_input"
                  id="alttext"
                  name="alttext"
                />
              </div>
            </div>
          </div>

          {/* //image field */}

          <div className="job_field_wrapper">
            <div className="job_field"></div>
            <div className="job_field">
              <label htmlFor="status">Status</label>
              <select
                onChange={HandleStatus}
                name="status"
                className="input_select"
                id="status"
                value={status}
              >
                <option value="0">ACTIVE </option>
                <option value="1">INACTIVE </option>
              </select>
            </div>
          </div>
          <div className=" mt-10 mb-8 flex justify-center  ">
            <button
              onClick={sendData}
              className="mx-auto rounded-md bg-primaryBlue py-3 px-20 text-white"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
}
